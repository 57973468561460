import { useState, useEffect } from 'react'

import {
  Chip,
  Tooltip,
  Modal,
  Grid,
  Heading,
  Button,
  Spinner,
  Form,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { DownloadIcon } from '@enterprise-ui/icons'

import { downloadEdiBySelectedFormat, formatDate } from '../../utils'
import ErrorTable from '../shared/ErrorTable'
import { getAuditErrorData } from '../../services/auditData'
import useUser from '../../hooks/useUser'
import { EDI_FORMATS } from '../../constants'

const STATUS_TABLE_COLUMNS = [
  {
    field: 'error_type',
    headerName: 'Error Type',
    width: 200,
    resizable: true,
    isRowHeader: true,
  },
  {
    field: 'error_message',
    headerName: 'Error Description',
    resizable: true,
  },
]

// Misc helpers components for audit data table row

const STATUS_COLOR_MAP = {
  Success: 'success',
  Failed: 'error',
  Warning: 'alert',
}

/**
 * Adds ... after text, if text is long
 */
export function EllipsesRow({ data, tooltipContent = '' }) {
  tooltipContent = tooltipContent || data

  return (
    <Tooltip
      content={tooltipContent}
      location="bottom"
      style={{ display: 'block' }}
    >
      <div className="hc-to-ellipsis hc-ws-no-wrap hc-ov-hidden cursor-pointer">
        {data}
      </div>
    </Tooltip>
  )
}

/**
 * Format date in mm-dd-yyyy hh:mm format
 */
export function CreatedDateRow({ date }) {
  return <div>{formatDate(date)}</div>
}

/**
 * Audit status row
 */
export function StatusRow({ rowData }) {
  const status = rowData.audit_status
  const { accessToken } = useUser()

  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])

  useEffect(() => {
    if (visible) {
      setLoading(true)

      const params = {
        audit_data_id: rowData.id,
      }
      getAuditErrorData(params, accessToken)
        .then((res) => {
          setErrors(res.data)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [visible, accessToken, rowData.id])

  const openStatusErrorModal = () => {
    if (status === 'Warning' || status === 'Failed') {
      setVisible(true)
    }
  }

  return (
    <>
      <Chip
        size="dense"
        color={STATUS_COLOR_MAP[status]}
        onClick={openStatusErrorModal}
      >
        {status}
      </Chip>
      {(status === 'Warning' || status === 'Failed') && (
        <Modal
          size="dense"
          isVisible={visible}
          onRefuse={() => setVisible(false)}
          headingText={
            <Grid.Container className="hc-pt-expanded hc-pl-expanded">
              <Heading className="hc-mr-lg" size={6}>
                Audit ID: {rowData.id}
              </Heading>
              <Heading size={6}>Load ID: {rowData.load_id}</Heading>
            </Grid.Container>
          }
        >
          {loading ? (
            <Grid.Container
              className="hc-pa-expanded"
              align="center"
              justify="center"
              style={{ height: 140 }}
            >
              <Spinner />
            </Grid.Container>
          ) : (
            <Grid.Container className="hc-pa-expanded">
              <ErrorTable
                heading="Error Details"
                headingSize={5}
                columnDefs={STATUS_TABLE_COLUMNS}
                rowData={errors}
              />
            </Grid.Container>
          )}
        </Modal>
      )}
    </>
  )
}

/**
 * Audit content row
 */
export function ContentRow({ data }) {
  const [visible, setVisible] = useState(false)
  const [downloadFormat, setDownloadFormat] = useState('edi')

  const openContentModal = () => {
    setVisible(true)
  }

  return (
    <>
      <div
        className="hc-to-ellipsis hc-ws-no-wrap hc-ov-hidden cursor-pointer"
        onClick={openContentModal}
      >
        {data}
      </div>
      <Modal
        size="dense"
        isVisible={visible}
        onRefuse={() => setVisible(false)}
      >
        <Grid.Container direction="column" className="hc-pa-expanded">
          <Grid.Item>
            <Heading size={4}>Content</Heading>
          </Grid.Item>
          <Grid.Item>
            <p>{data}</p>
          </Grid.Item>
          <Grid.Item>
            <Form.Field
              type="radio"
              value={downloadFormat}
              onUpdate={(id, value) => setDownloadFormat(value)}
              options={[
                { value: EDI_FORMATS.EDI, label: 'EDI Format' },
                {
                  value: EDI_FORMATS.PRETTY,
                  label: 'Pretty Print',
                },
              ]}
            />
            <Grid.Item></Grid.Item>
            <Button
              type="primary"
              fullWidth
              onClick={() =>
                downloadEdiBySelectedFormat(data, downloadFormat, 'content.edi')
              }
            >
              <EnterpriseIcon
                size="sm"
                icon={DownloadIcon}
                className="hc-mr-sm"
              />
              Download
            </Button>
          </Grid.Item>
        </Grid.Container>
      </Modal>
    </>
  )
}
