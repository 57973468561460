import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { FormSelect, FormTextField } from '@dlm/common'
import {
  Grid,
  Card,
  Heading,
  Form,
  Button,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { DownloadIcon } from '@enterprise-ui/icons'

import useUser from '../../hooks/useUser'
import { EDI_FORMATS, UI_USER } from '../../constants'
import { generate204EDI } from '../../services/generateEdi'

const FORM_INPUT_NAMES = {
  SCAC: 'scac',
  TYPE: 'type',
}

const EDI_204_TYPES = [
  { label: 'Please select a mode type', value: '' },
  { label: '1. First Mile Inbound - Vendor to RDC - Truckload', value: '1' },
  { label: '2. Middle Mile Outbound - RDC to Store - Truckload', value: '2' },
  { label: '3. Middle Mile Outbound - GM Store Sweep', value: '3' },
  { label: '4. Middle Mile Outbound - FDC to Store - Truckload', value: '4' },
  {
    label: '5. Middle Mile Outbound - Food w/backhaul - Truckload',
    value: '5',
  },
]

export const EDI_TYPE_MAP = EDI_204_TYPES.reduce((acc, curr) => {
  const value = curr.value
  const label = curr.label
  acc[value] = label
  return acc
}, {})

const RULES = {
  [FORM_INPUT_NAMES.SCAC]: {
    required: {
      value: true,
      message: 'Please enter 4 letter SCAC',
    },
    minLength: {
      value: 4,
      message: 'SCAC should have 4 letters',
    },
    maxLength: {
      value: 4,
      message: 'SCAC should have 4 letters',
    },
  },
  [FORM_INPUT_NAMES.TYPE]: {
    required: {
      value: true,
      message: 'Please select mode type',
    },
  },
}

/**
 * Component for Generate 204
 * @returns JSX
 */
export default function Generate204() {
  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: {
      [FORM_INPUT_NAMES.SCAC]: '',
      [FORM_INPUT_NAMES.TYPE]: '',
    },
  })

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = formContext

  const { accessToken } = useUser()
  const makeToast = ToastProvider.useToaster()

  const [isLoading, setLoading] = useState(false)
  const [downloadFormat, setDownloadFormat] = useState('edi')

  const onSubmit = (data) => {
    setLoading(true)

    const params = {
      scac: data.scac.toUpperCase(),
      type: data.type,
      source: UI_USER,
    }
    generate204EDI(params, accessToken, downloadFormat)
      .then(() => {
        makeToast({
          type: 'success',
          heading: 'Download Success',
          message: 'EDI file downloaded successfully',
        })
        reset()
      })
      .catch((error) => {
        if (error.response != null && error.response.status === 400) {
          makeToast({
            type: 'error',
            heading: 'Error',
            message: error.response.data,
          })
        } else if (error.response != null && error.response.status === 403) {
          makeToast({
            type: 'error',
            heading: 'Unauthorized',
            message: 'Please contact Admin',
          })
        } else {
          makeToast({
            type: 'error',
            heading: 'Error',
            message: `Load Tender Response - Failed to Generate for SCAC - ${
              data.scac
            } and Mode Type - ${EDI_TYPE_MAP[data.type]}`,
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Card className="hc-pa-md edi-container">
      <Grid.Container direction="column" className="hc-pa-md">
        <Grid.Item>
          <Heading size={3}>Generate 204</Heading>
        </Grid.Item>
        <Grid.Item>
          <Grid.Container justify="center">
            <Grid.Item xs={5}>
              <Card className="hc-pa-3x">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <FormTextField
                    data-testid="scac-input"
                    placeholder="Please enter 4 letter SCAC"
                    name={FORM_INPUT_NAMES.SCAC}
                    className="hc-mb-lg capital-input"
                    formContext={formContext}
                    error={errors[FORM_INPUT_NAMES.SCAC]}
                    label="SCAC"
                    rules={RULES[FORM_INPUT_NAMES.SCAC]}
                    errorText={errors[FORM_INPUT_NAMES.SCAC]?.message}
                  />
                  <FormSelect
                    data-testid="type-input"
                    name={FORM_INPUT_NAMES.TYPE}
                    className="hc-mb-lg"
                    formContext={formContext}
                    error={errors[FORM_INPUT_NAMES.TYPE]}
                    label="Mode Type"
                    rules={RULES[FORM_INPUT_NAMES.TYPE]}
                    errorText={errors[FORM_INPUT_NAMES.TYPE]?.message}
                    options={EDI_204_TYPES}
                  />
                  <Form.Field
                    type="radio"
                    value={downloadFormat}
                    onUpdate={(id, value) => setDownloadFormat(value)}
                    options={[
                      { value: EDI_FORMATS.EDI, label: 'EDI Format' },
                      {
                        value: EDI_FORMATS.PRETTY,
                        label: 'Pretty Print',
                      },
                    ]}
                  />
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    data-testid="204-download-button"
                    fullWidth
                    className="hc-mt-md"
                  >
                    <EnterpriseIcon
                      icon={DownloadIcon}
                      size="sm"
                      className="hc-mr-xs"
                    />
                    Download 204
                  </Button>
                </Form>
              </Card>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
    </Card>
  )
}
