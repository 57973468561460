import { format } from 'date-fns'

import {
  EllipsesRow,
  CreatedDateRow,
  StatusRow,
  ContentRow,
} from '../components/AuditData/Helpers'
import { EDI_TYPE_MAP } from '../components/Generate204'
import { EDI_FORMATS } from '../constants'

/**
 * @param {*} errors
 * @returns Formatted EDI errors for Table component
 */
export function formatEdiErrorData(errors) {
  const formttedErrors = []
  errors.forEach((err) => {
    err.responsejson.forEach((responseJsonData) => {
      const error = {
        uniqueTrackingNumber: err.unique_tracking_number,
        errorType: responseJsonData.message,
        errorDescription: responseJsonData.description,
      }
      formttedErrors.push(error)
    })
  })
  return formttedErrors
}

/**
 * Map audit table keys for sorting & searching
 * @param {*} rows
 */
export function mapAuditTableKeys(rows = []) {
  return rows.map((row) => {
    const inputData = row.source === 'AS2' ? '' : row.input_data
    const proNum =
      row.pro_number != null
        ? row.pro_number.replaceAll('[', '').replaceAll(']', '')
        : ''

    return {
      ...row,
      inputData: {
        cellValue: inputData,
        cellDisplay: <ContentRow data={inputData} />,
      },
      location: {
        cellValue: row.location,
        cellDisplay: <EllipsesRow data={row.location} />,
      },
      activity: {
        cellValue: row.activity,
        cellDisplay: <EllipsesRow data={row.activity} />,
      },
      ediType: row.edi_type !== null ? row.edi_type.split('_')[1] : '',
      loadId: row.load_id,
      userId: row.user_id,
      modeType: {
        cellValue: row.mode_type,
        cellDisplay: (
          <EllipsesRow
            data={row.mode_type}
            tooltipContent={EDI_TYPE_MAP[row.mode_type]}
          />
        ),
      },
      isaNumber: row.isa_number,
      createdTimestamp: {
        cellValue: row.created_timestamp,
        cellDisplay: <CreatedDateRow date={row.created_timestamp} />,
      },
      auditStatus: {
        cellValue: row.audit_status,
        cellDisplay: <StatusRow rowData={row} />,
      },
      proNumber: {
        cellValue: proNum,
        cellDisplay: <EllipsesRow data={proNum} />,
      },
    }
  })
}

/**
 * Format date in MM-dd-yyyy HH:mm
 * @param {*} date
 */
export function formatDate(inputDate) {
  return inputDate ? format(new Date(inputDate), 'MM-dd-yyyy HH:mm') : undefined
}

/**
 * Download audit table data
 * @param {*} columns
 * @param {*} data
 */
export function downloadAuditTableData(columns, data) {
  const csvData = [[]]
  columns.forEach((col) => {
    csvData[0].push(col.headerName)
  })

  data.forEach((row) => {
    const rowData = []
    columns.forEach((col) => {
      const field = col.field
      let val =
        typeof row[field] === 'string' ? row[field] : row[field]?.cellValue
      // eslint-disable-next-line
      val = val?.includes('\n') ? `\"${val}\"` : val
      rowData.push(val)
    })
    csvData.push(rowData)
  })

  const csvHeader = 'data:text/csv;charset=utf-8,'
  const csvContent = csvData.map((row) => row.join(',')).join('\n')
  const encodedUri = csvHeader + encodeURIComponent(csvContent)

  const element = document.createElement('a')
  element.setAttribute('href', encodedUri)
  element.setAttribute(
    'download',
    `COS_Audit_Data_${new Date().toLocaleString()}.csv`,
  )
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

/**
 * Convert Prettry format to EDI format
 * @param {*} edi
 */
export function prettyToEdiFormat(edi) {
  const ediArray = edi.split('\n')
  let withTild = ediArray.join('~')
  withTild += '~' // append ~ at the end
  return withTild
}

/**
 * Convert EDI format to Prettry format
 * @param {*} edi
 */
export function ediToPrettyFormat(edi) {
  const ediArray = edi.split('~')
  ediArray.pop()
  const withLines = ediArray.join('\n')
  return withLines
}

/**
 * Download the given EDI into the given format
 * @param {*} edi
 * @param {*} format edi|pretty
 */
export function downloadEdiBySelectedFormat(edi, targetFormat, filename) {
  let content
  const givenFormat = edi.endsWith('~') ? EDI_FORMATS.EDI : EDI_FORMATS.PRETTY

  if (targetFormat === EDI_FORMATS.EDI && givenFormat === EDI_FORMATS.EDI) {
    content = edi
  } else if (
    targetFormat === EDI_FORMATS.EDI &&
    givenFormat === EDI_FORMATS.PRETTY
  ) {
    content = prettyToEdiFormat(edi)
  } else if (
    targetFormat === EDI_FORMATS.PRETTY &&
    givenFormat === EDI_FORMATS.EDI
  ) {
    content = ediToPrettyFormat(edi)
  } else if (
    targetFormat === EDI_FORMATS.PRETTY &&
    givenFormat === EDI_FORMATS.PRETTY
  ) {
    content = edi
  }

  const elem = document.createElement('a')
  elem.href = window.URL.createObjectURL(new Blob([content]))
  elem.download = filename
  elem.click()
}
