import axios from 'axios'

import apiConfig from '../config/apiConfig'
import { downloadEdiBySelectedFormat } from '../utils'

/**
 * Downloads 204 EDI
 * @param {*} params
 * @param {*} accessToken
 */
export async function generate204EDI(params, accessToken, downloadFormat) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiConfig.urls.carrierOnboarding}/generate_204`, {
        params: params,
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .replace(/['"]+/g, '')

        downloadEdiBySelectedFormat(response.data, downloadFormat, filename)
        resolve()
      })
      .catch((error) => {
        reject(error)
      })
  })
}
